import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import keys from 'lodash/keys'

import etchESignSRC from './img/etch-e-sign.svg'
import etchESignAPISRC from './img/etch-e-sign-api.svg'
import gettingStartedSRC from './img/getting-started.svg'
import graphqlSRC from './img/graphql.svg'
import pdfFillingSRC from './img/pdf-filling.svg'
import pdfGenerationSRC from './img/pdf-generation.svg'
import shapesSRC from './img/shapes.svg'
import webhooksSRC from './img/webhooks.svg'
import workflowsSRC from './img/workflows.svg'
import workflowUIURLSSRC from './img/workflow-ui-urls.svg'
import simpleSRC from './img/simple.svg'
import scalableSRC from './img/scalable.svg'
import reliableSRC from './img/reliable.svg'
import developerFocusedSRC from './img/developer-focused.svg'
import embeddedBuildersSRC from './img/embedded-builders.svg'
import whitelabelingSRC from './img/whitelabeling.svg'
import iframeEventsSRC from './img/iframe-events.svg'

// TODO: deprecate ProductIcon component in favor of GraphicIcon from @anvilco-pkg/ui
const TYPE_CONFIG = {
  gettingStarted: {
    src: gettingStartedSRC,
    srcOffset: [0, 0],
    alt: 'Getting started',
  },
  graphql: {
    src: graphqlSRC,
    srcOffset: [0, 0],
    alt: 'GraphQL',
  },
  etchESign: {
    src: etchESignSRC,
    srcOffset: [0, 0],
    alt: 'Etch E-sign',
  },
  etchESignAPI: {
    src: etchESignAPISRC,
    srcOffset: [0, -9],
    alt: 'Etch E-sign API',
  },
  webhooks: {
    src: webhooksSRC,
    srcOffset: [0, 0],
    alt: 'Webhooks',
  },
  pdfFilling: {
    src: pdfFillingSRC,
    srcOffset: [0, 0],
    alt: 'PDF filling',
  },
  pdfGeneration: {
    src: pdfGenerationSRC,
    srcOffset: [0, 0],
    alt: 'PDF generation',
  },
  workflows: {
    src: workflowsSRC,
    srcOffset: [0, 0],
    alt: 'Workflows',
  },
  workflowUIURLs: {
    src: workflowUIURLSSRC,
    srcOffset: [-4, -8],
    alt: 'Workflow UI URLs',
  },
  objectShapes: {
    src: shapesSRC,
    srcOffset: [0, 0],
    alt: 'Object shapes',
  },
  simple: {
    src: simpleSRC,
    srcOffset: [0, 0],
    alt: 'Simple to use',
  },
  scalable: {
    src: scalableSRC,
    srcOffset: [0, 0],
    alt: 'Scalable solution',
  },
  reliable: {
    src: reliableSRC,
    srcOffset: [0, 0],
    alt: 'Reliable API',
  },
  developerFocused: {
    src: developerFocusedSRC,
    srcOffset: [0, 0],
    alt: 'Developer focused technology',
  },
  embeddedBuilders: {
    src: embeddedBuildersSRC,
    srcOffset: [0, 0],
    alt: 'Embedded Builders',
  },
  whitelabeling: {
    src: whitelabelingSRC,
    srcOffset: [0, 0],
    alt: 'Whitelabeling',
  },
  iframeEvents: {
    src: iframeEventsSRC,
    srcOffset: [0, 0],
    alt: 'iFrame Events',
  },
}

const ImageContainer = styled.span`
  display: block;
  position: relative;
  height: 75px;
  margin-bottom: 30px;
  width: 100%;

  /*
   * Abs position the actual images so we have a consistent image size provided
   * by the container. The image sizes are all over the map.
   **/
  img {
    display: block;
    position: absolute;
    bottom: 0;
    box-shadow: none !important;

    ${({ align }) =>
      align === 'left' &&
      `
      left: 0;
    `}

    ${({ align }) =>
      align === 'center' &&
      `
      margin-left: 50%;
      transform: translateX(-50%);
    `}
  }
`

const ProductIcon = ({ type, align, ...others }) => {
  const config = TYPE_CONFIG[type]
  if (!config) return null

  const left = align === 'left' ? config.srcOffset[0] : null
  const bottom = config.srcOffset[1]

  return (
    <ImageContainer align={align} {...others}>
      <img
        src={config.src}
        alt={config.alt}
        style={{
          left,
          bottom,
        }}
      />
    </ImageContainer>
  )
}

ProductIcon.defaultProps = {
  align: 'left',
}

ProductIcon.propTypes = {
  type: PropTypes.oneOf(keys(TYPE_CONFIG)).isRequired,
  align: PropTypes.oneOf(['left', 'center']).isRequired,
}

export default ProductIcon
