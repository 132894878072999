import Analytics from '@anvilco-pkg/customer-data/segment/client'
import { isBrowser } from 'helpers'

/**
 * parameters:
 * - method: segment calls (https://segment.com/docs/connections/spec/). Defaults to 'track' (bc events). Possible values:
 *     'track', 'identify', 'page'
 * - debugMode: if true, will log events to console instead of sending to segment
 *
 * returns:
 * - function that accepts an object of properties to send to segment
 */
export default function useAnalytics ({
  method = 'track',
  debugMode = false,
  eventName,
  userId,
}) {
  let eventHandler = () => {
    if (debugMode) {
      console.log('Initial analytics event handler - does nothing.')
    }
  }

  if (isBrowser()) {
    const identifier = eventName || userId

    eventHandler = function (properties) {
      properties = {
        page: window?.location?.pathname,
        ...properties,
      }
      // TODO: 'category' property seems to be on each event in the docs... is it worth adding?
      // if so, prob should just add it here and not have it be a parameter. i think it'd be 'Marketing Site'

      // maybe some validation for event? don't want runtime errors... log an error? sentry or something would be nice here...
      if (debugMode) {
        console.log('Analytics event:', identifier, properties)
      } else {
        Analytics?.[method](identifier, properties)
      }
    }
  }

  return eventHandler
}
