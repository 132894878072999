import Analytics from '@anvilco-pkg/customer-data/segment/client'

// dunno why import doesn't work for ui helpers... but this is fine for now
const helpers = require('@anvilco-pkg/ui/helpers')

// NOTE: this file should NOT import any React components.
// this file is lower level than regular React code, and should be
// treated as if React is unavailable
export const onRouteUpdate = ({ location, prevLocation }) => {
  const { isInEu, getCookie, COOKIE_CONSENT_NAME, YES_CONSENT } = helpers

  const notInEu = !isInEu()
  const inEuAndConsented =
    isInEu() && getCookie(COOKIE_CONSENT_NAME) === YES_CONSENT
  if ((notInEu || inEuAndConsented) && window.analytics && Analytics.page) {
    Analytics?.page()
  }
}
