// copy pasta from helpers, always keep in sync
// we copy here because  we can't import helpers in gatsby-node.js
const APP_URL = process.env.PROD_APP_URL || process.env.APP_URL

// slugs
export const HELP_SLUG = 'help'
export const BLOG_SLUG = 'blog'
export const FORMS_SLUG = 'forms'
export const PACK_SLUG = 'pack'
export const HTML_TO_PDF_CONVERTER_SLUG = 'free-html-to-pdf-converter'
export const MARKDOWN_TO_PDF_CONVERTER_SLUG = 'free-markdown-to-pdf-converter'
export const FILL_LANGUAGE_PREFIX_SLUG = 'products/fill-pdf-in-'
export const GEN_LANGUAGE_PREFIX_SLUG = 'products/generate-pdf-in-'
export const ETCH_LANGUAGE_PREFIX_SLUG = 'products/sign-pdf-in-'
export const LENNY_SLUG = 'lenny'

// gatsby config values
export const SOURCE_LANGUAGE_FILL_NAME = 'language-fill'
export const SOURCE_LANGUAGE_GEN_NAME = 'language-gen'
export const SOURCE_LANGUAGE_ETCH_NAME = 'language-etch'

export const WORKFLOW_EXAMPLE_URL = `${APP_URL}/form/anvil-inc/welcome-yellow/`
export const WORKFLOW_WHITELABLED_EXAMPLE_URL = `${APP_URL}/form/anvil-inc/welcome-blue/`
export const ETCH_EXAMPLE_URL = `${APP_URL}/weld/anvil-inc/etchsignsample`
export const ETCH_WHITELABELED_EXAMPLE_URL = `${APP_URL}/weld/anvil-demo/etchsignsample`

// article based stuff; only used for help center rn
export const TAG_INDEX = 'INDEX'
export const TAG_DIVIDER = 'DIVIDER'
export const TAG_ALL_ARTICLES = 'All'
export const DEFAULT_PAGE_SIZE = 12

// navbar
// TODO: audit these values
export const MOBILE_NAV_BAR_HEIGHT = 104
export const NAV_BAR_HEIGHT = 78
export const ScrollDuration = 300

// this seems sensitive... move to env? NOTE: gonna end in the browswer anyways, so can't keep secure
export const SEGMENT_DEV_KEY = 'J0RX1y0Dh17sAHL9eBa8mXCN9tgwQ5JB'
export const SEGMENT_PROD_KEY = 'vCNVLVEKIxyo5DpCvXO91DwjCd4JHBtX'

export const DEFAULT_KEYWORDS = 'pdf,forms,paperwork,process,document,workflow'

/**
 * default style values
 */
export const DEFAULT_BODY_FONT_SIZE_NUM = 16
export const DEFAULT_BODY_FONT_SIZE_STRING = `${DEFAULT_BODY_FONT_SIZE_NUM}px`
export const DEFAULT_MOBILE_BODY_FONT_SIZE_NUM = 15
export const DEFAULT_MOBILE_BODY_FONT_SIZE_STRING = `${DEFAULT_MOBILE_BODY_FONT_SIZE_NUM}px`

/**
 * REUSABLE CLASSNAMES
 */

export const GREEN_UNDERLINE = 'green-underline'
export const CLASS_NO_LINK_STYLES = 'no-link-styles'
export const CLASS_STYLE_AS_LINK = 'style-as-link'

export const VIDEO_URL =
  'https://www.youtube.com/embed/L_ba5EnJkCc?vq=hd1080&rel=0&color=white&autoplay=1&playsinline=0&enablejsapi=1'
