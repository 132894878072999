import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'
import Children from 'react-children-utilities'

import { pixels } from 'media'
import useAnalytics from 'hooks/useAnalytics'
import {
  EVENT_OUTBOUND_LINK_CLICKED,
  EVENT_MAILTO_LINK_CLICKED,
  EVENT_TELEPHONE_LINK_CLICKED,
} from 'lib/analytics/events'
import useViewportWidth from 'hooks/useViewportWidth'

import {
  getQueryString,
  parseURL,
  isMailtoLink,
  isTelephoneLink,
  isAbsoluteURL,
  APP_URL,
} from 'helpers'
import { NAV_BAR_HEIGHT, MOBILE_NAV_BAR_HEIGHT } from 'consts'

// TODO: all links on the marketing site should use this one. some are still straggling
// the links on programming language logo bars are not universal link

// TODO: this should move to the UI package so the app can use it too. we'd pass in the
// app/marketing site components as props so we can use the functionality in both places

// these are the query params we want to forward to the next page. all others are ignored
const FORWARDED_PROPS = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

// these are the domains we want to forward query params to, besides our own (usually useanvil.com),
// all others are ignored. NOTE: this doesn't work right now, some weird state change issue
const FORWARDED_URLS = [APP_URL]

const SCROLL_DURATION = 300
const getScrollOffset = (isMobile) =>
  isMobile ? -MOBILE_NAV_BAR_HEIGHT : -NAV_BAR_HEIGHT

// Will use the proper link component depending on props passed
//
// <UniversalLink to="something" /> // => <Scroll.Link/> on this page
// <UniversalLink to="/something" /> // => <Link/> to other marketing page
// <UniversalLink href="https://something.com" /> // => <a/> to other another site

const VWScrollLink = ({ to, scrollOffset, ...others }) => {
  const vw = useViewportWidth()
  const offset = scrollOffset + getScrollOffset(vw <= pixels.desktop)

  return (
    <ScrollLink
      smooth
      duration={SCROLL_DURATION}
      offset={offset}
      href={`#${to}`}
      to={to}
      {...others}
    />
  )
}

// 3 potential links covered by this component:
// 1. external: <UniversalLink to="https://something.com" />
// 2. internal: <UniversalLink to="/something" />
// 3. scroll: <UniversalLink to="something" />
const UniversalLink = ({
  to,
  href,
  scrollOffset,
  state,
  target,
  children,
  onClick,
  disableAnalytics, // used bc our Buttons (which also have analytics) use this component. we only need 1 event
  ...others
}) => {
  if (href) {
    console.warn('UniversalLink: href is deprecated. use to instead')
  }

  const [queryParams, setQueryParams] = useState('')

  const isScrollLink = to && !isAbsoluteURL(to) && !to.startsWith('mailto:')
  const isPageLink = isScrollLink && to.indexOf('/') > -1

  // shouldForwardQueryParams isn't meshing well with the state change in the useEffect
  // for query params. so we're just going to allow any domain to have query params forwarded
  // eslint-disable-next-line no-unused-vars
  const shouldForwardQueryParams =
    FORWARDED_URLS.some((forwardedURL) => {
      if (!to || !isAbsoluteURL(to)) return false
      const { host } = new URL(to)
      return forwardedURL.includes(host)
    }) || isPageLink

  // onmousedown for mobile is ontouchstart
  const handleOnMouseDown = (e) => {
    if (state) {
      Object.entries(state).forEach(([key, value]) => {
        localStorage.setItem(key, value)
      })
    }
  }

  useEffect(() => {
    const { query } = parseURL(document.location.href) || {}
    const forwardedQueryParams = {}
    Object.entries(query).forEach(([key, value]) => {
      if (FORWARDED_PROPS.includes(key)) {
        forwardedQueryParams[key] = value
      }
    })

    const newQueryParams = getQueryString(forwardedQueryParams)
    if (query && Object.keys(query).length > 0) {
      setQueryParams(newQueryParams)
    }
  }, [])

  const nonScrollTo = to || href
  // TODO: enable restricting domains for query param forwarding
  const finalTo = `${nonScrollTo}${queryParams}`

  if (isPageLink) {
    return (
      <Link
        to={finalTo}
        state={state}
        {...others}
        onMouseDown={handleOnMouseDown}
        onClick={onClick}
      >
        {children}
      </Link>
    )
  } else if (isScrollLink) {
    return (
      <VWScrollLink
        to={to}
        scrollOffset={scrollOffset}
        target={target}
        state={state}
        {...others}
        onMouseDown={handleOnMouseDown}
        onClick={onClick}
      >
        {children}
      </VWScrollLink>
    )
  }

  const telephoneClickHandler = useAnalytics({
    eventName: EVENT_TELEPHONE_LINK_CLICKED,
  })
  const mailToClickHandler = useAnalytics({
    eventName: EVENT_MAILTO_LINK_CLICKED,
  })
  const outboundLinkClickHandler = useAnalytics({
    eventName: EVENT_OUTBOUND_LINK_CLICKED,
  })

  const handleLinkClick = (e) => {
    const properties = {
      click_url: finalTo,
      action: 'Clicked',
      link_text: Children.onlyText(children),
    }

    if (isMailtoLink(finalTo)) {
      mailToClickHandler(properties)
    } else if (isTelephoneLink(finalTo)) {
      telephoneClickHandler(properties)
    } else {
      outboundLinkClickHandler(properties)
    }
  }

  return (
    <a
      {...others}
      href={finalTo}
      target={target}
      onClick={(e) => {
        !disableAnalytics && handleLinkClick(e)
        onClick && onClick(e)
      }}
    >
      {children}
    </a>
  )
}

UniversalLink.defaultProps = {
  scrollOffset: 0,
  target: '_blank',
  disableAnalytics: false,
}

UniversalLink.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  scrollOffset: PropTypes.number,
  target: PropTypes.string,
  // link state, passed to gatsby link
  state: PropTypes.object,
  disableAnalytics: PropTypes.bool,
}

export default UniversalLink
