// Uses breakpoints from https://github.com/jxnblk/grid-styled#theming

import { css } from 'styled-components'

export const breakpoints = ['32em', '48em', '64em', '90em']

export const sizes = {
  // 0. 20em - 320px - "mobile"
  tablet: breakpoints[0], // 1. 32em - 512px - "tablet"
  desktop: breakpoints[1], // 2. 48em - 768px - "desktop"
  giant: breakpoints[2], // 3. 64em - 1024px - "giant"
  beyondGiant: breakpoints[3], // 4. 90em - 1440px - "beyond giant"
}

export const keys = Object.keys(sizes)

export const spacing = [0, 10, 15, 30, 64]

// iterate through the sizes and create a media template
export default Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (min-width: ${sizes[label]}) {
      ${css(...args)}
    }
  `
  return accumulator
}, {})

export const pixels = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = Number(sizes[label].replace('em', '')) * 16
  return accumulator
}, {})
