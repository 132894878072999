import { useState, useEffect } from 'react'
import debounce from 'lodash/debounce'
import { getViewportDimension } from 'lib/dom'

const useViewportWidth = () => {
  const [vw, setVw] = useState(getViewportDimension('width'))

  useEffect(() => {
    const handleResize = debounce(() => {
      setVw(getViewportDimension('width'))
    }, 50)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // empty dependency array to ensure the effect runs only once after mount

  return vw
}

export default useViewportWidth
