import React from 'react'
import styled from 'styled-components'

import UniversalLink from 'components/UniversalLink'

const LinkStyled = styled(UniversalLink)`
  ${({ inverse }) =>
    inverse &&
    `
    color: white;

    &:focus,
    &:visited,
    &:active {
      text-decoration: none;
      color: white !important;
    }

    &:hover {
      text-decoration: underline;
      color: white;
    }
  `}
`

const NewLink = ({ to, ...others }) => <LinkStyled to={to} {...others} />

export default NewLink
