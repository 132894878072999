import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import qs from 'qs'
import { Box } from 'reflexbox/styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import media from '../media'

export const APP_URL = process.env.PROD_APP_URL || process.env.APP_URL
export const SCROLL_NAME_CONTACT_SALES = 'contact-sales'
export const SCROLL_NAME_CONTACT_SUPPORT = 'contact-support'

export const validateAppURLexists = () => {
  if (APP_URL === undefined || APP_URL === null || APP_URL === '') {
    throw new Error(
      'No app URL specified, please specify one in your environment.'
    )
  }
}

// Like _.compact, but for objects
//
// collapse all the null / undefineds.
const defaultComparator = (v) => v != null
export function compactObject (obj, comparator) {
  return _.pickBy(obj, comparator || defaultComparator)
}

export function isAbsoluteURL (url) {
  return /^(?:[a-z+]+:)?\/\//i.test(url)
}

// Will return a query string collapsing any null / undefined values
//
// * `query` - {Object}
//
// e.g. getQueryString({}) => ''
// e.g. getQueryString({int: 1, nope: null, str: 'ok'}) => '?int=1&str=ok'
export function getQueryString (query) {
  query = compactObject(query)
  const queryStr = qs.stringify(query)
  return queryStr ? `?${queryStr}` : ''
}

function parseURLWithChar (url, char) {
  const splitURL = url.split(char)
  return {
    url: splitURL[0],
    afterChar: splitURL[1],
  }
}

export function parseURL (url) {
  const { url: baseURL, afterChar: queryParams } = parseURLWithChar(url, '?')
  const query = parseQueryString(queryParams)
  return {
    url: baseURL,
    query,
  }
}

export function buildURL (path, query) {
  query = compactObject(query)
  const queryStr = getQueryString(query)
  return queryStr ? `${path}${queryStr}` : path
}

export function parseQueryString (queryString) {
  const strippedQueryString = queryString?.startsWith('?')
    ? queryString.slice(1)
    : queryString
  return qs.parse(strippedQueryString)
}

export function appendQueryString (url, newQuery) {
  const { url: newURL, query } = parseURL(url)
  const totalQueryParams = _.assign({}, query, newQuery)
  return buildURL(newURL, totalQueryParams)
}

export function extractUTMQueryParams (queryParams) {
  return _.pickBy(queryParams, (value, key) => key.indexOf('utm_') === 0)
}

export function classString (classes) {
  return _.compact(classes).join(' ')
}

const ContentBox = styled(Box)
  .attrs({
    as: 'span',
  })
  .withConfig({
    shouldForwardProp: (prop) =>
      ['mobile', 'tablet', 'desktop', 'giant', 'beyondGiant'].indexOf(prop) ===
      -1,
  })`
  .tablet,
  .desktop,
  .giant,
  .beyondGiant {
    display: none;
  }

  ${({ tablet }) =>
    tablet &&
    media.tablet`
    .mobile {
      display: none;
    }

    .tablet {
      display: inline;
    }
  `}

  ${({ desktop }) =>
    desktop &&
    media.desktop`
    .mobile,
    .tablet {
      display: none;
    }

    .desktop {
      display: inline;
    }
  `}

  ${({ giant }) =>
    giant &&
    media.giant`
    .mobile,
    .tablet,
    .desktop {
      display: none;
    }

    .giant {
      display: inline;
    }
  `}

  ${({ beyondGiant }) =>
    beyondGiant &&
    media.beyondGiant`
    .mobile,
    .tablet,
    .desktop,
    .giant {
      display: none;
    }

    .beyondGiant {
      display: inline;
    }
  `}
`

// mobile is required - mobile first dev
export const contentByBreakpoint = ({
  mobile,
  tablet,
  desktop,
  giant,
  beyondGiant,
}) => {
  if (!mobile) {
    throw new Error('Mobile content must be supplied')
  }

  return (
    <ContentBox
      mobile={mobile}
      tablet={tablet}
      desktop={desktop}
      giant={giant}
      beyondGiant={beyondGiant}
    >
      <span className="mobile">{mobile}</span>
      {tablet && <span className="tablet">{tablet}</span>}
      {desktop && <span className="desktop">{desktop}</span>}
      {giant && <span className="giant">{giant}</span>}
      {beyondGiant && <span className="beyondGiant">{beyondGiant}</span>}
    </ContentBox>
  )
}

export function pluralize (number, singular, plural) {
  plural = plural || singular + 's'
  return number === 1 ? singular : plural
}

// Could use something like https://github.com/blakeembrey/pluralize
export function singularize (plural) {
  if (_.last(plural) === 's') return plural.slice(0, plural.length - 1)
  return plural
}

export const isFullURL = (str) => {
  return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
    str
  )
}

export const slugToTag = (slug) => {
  return slug
    ? _.capitalize(slug.replaceAll('-', ' ')).replace('Pdf', 'PDF')
    : null
}

export const isBrowser = () => typeof window !== 'undefined'
export const isHTMLDocument = () => typeof document !== 'undefined'

function toCamelKeys (obj) {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[_.camelCase(key)] = obj[key]
    return accumulator
  }, {})
}

function nullifyEmptyStringValues (obj) {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[key] = obj[key] === '' ? null : obj[key]
    return accumulator
  }, {})
}

export const getSeoValues = (nodes) => {
  if (nodes?.length !== 1) return

  return nullifyEmptyStringValues(toCamelKeys(nodes[0]))
}

// domain corresponds to the directory name under 'marketing-site' in public-assets repo
// NOTE: svg is not directly supported for social covers... by the time it gets here, it should be a png
export const getSocialCoverURL = (
  domain,
  landingPage,
  { ext = null, additionalPath = '' } = {}
) => {
  if (!landingPage && !domain)
    throw new Error('Must provide a landing page or domain')

  return `https://raw.githubusercontent.com/anvilco/public-assets/master/marketing-site/${
    domain ? `${domain.toLowerCase()}/` : ''
  }${(landingPage || 'index').toLowerCase()}${additionalPath}.${ext || 'png'}`
}

export function extractNumber (value) {
  if (value === null || value === undefined) return null
  if (typeof value === 'number') return value
  if (typeof value === 'string') {
    const match = value.match(/(-?\d+(\.\d+)?)/)
    if (match) return parseFloat(match[1])
  }
  return null
}

export function extractUnit (value) {
  if (value === null || value === undefined) return null
  if (typeof value === 'string') {
    const match = value.match(/([a-zA-Z%]+)/)
    if (match) {
      // if (!SUPPORTED_UNITS.includes(match[1])) return null // TODO: add in supported units
      return match[1]
    }
  }
  return null
}

export function getLengthInfo (value) {
  if (value === null || value === undefined) return null
  const number = extractNumber(value)
  const unit = extractUnit(value) || 'px' // defaults to px
  return { number, unit }
}

export const IN_TO_PX = 96
export const CM_TO_PX = 37.795275590551
export const MM_TO_PX = 3.7795275590551
export const PT_TO_PX = 1.3333333333333
export const PC_TO_PX = 16
export const EM_TO_PX = 16
export const REM_TO_PX = 16
export function convertToPx (value, unit) {
  if (value === null || value === undefined) return null
  if (unit === null || unit === undefined) return value
  switch (unit) {
    case 'px':
      return value
    case 'in':
      return value * IN_TO_PX
    case 'cm':
      return Math.round(value * CM_TO_PX)
    case 'mm':
      return Math.round(value * MM_TO_PX)
    case 'pt':
      return Math.round(value * PT_TO_PX)
    case 'pc':
      return value * PC_TO_PX
    case 'em': // TODO: em and rem should be based off of the font size of the root element, maybe pt and pc too?
      return value * EM_TO_PX
    case 'rem':
      return value * REM_TO_PX
    default:
      throw new Error(`Unsupported unit: ${unit}`)
  }
}

/*
 * NOTE: only supports px in the value arg. amount can be anything
 *
 * @param {string} value - a string representing a length value
 * @param {string} operation - a string representing an arithmetic operation. can be:
 *   - + (add)
 *   - - (subtract)
 *   - * (multiply)
 *   - / (divide)
 * @param {number} amount - a number (or string with unit) to perform the arithmetic operation on
 */
export const calculateLength = (value, operation, amount) => {
  const lengthInfo = getLengthInfo(value)
  const amountInfo = getLengthInfo(amount)
  if (lengthInfo?.number === null || lengthInfo?.number === undefined)
    return null
  if (lengthInfo?.unit === null || lengthInfo?.unit === undefined) return null

  const amountIsNull = amount === null || amount === undefined

  // TODO: add in support for different units. convert amount to value's units and return in value's units

  const { number, unit } = lengthInfo
  const pxNumber = !amountIsNull
    ? convertToPx(amountInfo?.number, amountInfo?.unit)
    : operation === '*' || operation === '/'
      ? 1
      : 0

  switch (operation) {
    case '+': {
      return `${number + pxNumber}${unit}`
    }
    case '-': {
      return `${number - pxNumber}${unit}`
    }
    case '*': {
      return `${number * pxNumber}${unit}`
    }
    case '/': {
      return `${number / pxNumber}${unit}`
    }
  }
}

// not currently used
export const minLength = (length1, length2) => {
  const length1Info = getLengthInfo(length1)
  const length2Info = getLengthInfo(length2)
  if (length1Info?.number === null || length1Info?.number === undefined)
    return null
  if (length1Info?.unit === null || length1Info?.unit === undefined) return null
  if (length2Info?.number === null || length2Info?.number === undefined)
    return null
  if (length2Info?.unit === null || length2Info?.unit === undefined) return null

  const { number: length1Number, unit: length1Unit } = length1Info
  const { number: length2Number, unit: length2Unit } = length2Info
  const length1Px = convertToPx(length1Number, length1Unit)
  const length2Px = convertToPx(length2Number, length2Unit)
  return length1Px < length2Px ? length1 : length2
}

export const splitLength = (length) => {
  const array = length?.trim()?.split(' ')

  switch (array?.length) {
    case 1:
      return {
        top: array?.[0],
        right: array?.[0],
        bottom: array?.[0],
        left: array?.[0],
      }
    case 2:
      return {
        top: array?.[0],
        right: array?.[1],
        bottom: array?.[0],
        left: array?.[1],
      }
    case 3:
      return {
        top: array?.[0],
        right: array?.[1],
        bottom: array?.[2],
        left: array?.[1],
      }
    case 4:
      return {
        top: array?.[0],
        right: array?.[1],
        bottom: array?.[2],
        left: array?.[3],
      }
  }
}

export const getLength = (length) => {
  switch (typeof length) {
    case 'number':
      return {
        top: `${length}px`,
        right: `${length}px`,
        bottom: `${length}px`,
        left: `${length}px`,
      }
    case 'string':
      return splitLength(length)
    case 'object': // arrays are objects
      return length
  }
}

// TODO: build this out more + unit test it
// TODO: formalize the format between this and splitLength return values
export const getThemeLength = ({ theme, key, component, breakpoint }) => {
  // HACK: temporary fix so the new tablet paddings can default to mobile paddings if not specified
  const tabletValue =
    theme?.components?.[component]?.tablet?.[key] ||
    theme?.components?.[component]?.mobile?.[key] ||
    theme?.components?.[component]?.[key]
  // TODO: support non-component length values
  const value =
    breakpoint === 'tablet'
      ? tabletValue
      : theme?.components?.[component]?.[breakpoint]?.[key] ||
        theme?.components?.[component]?.[key]

  return getLength(value)
}

export const getPaddingValues = (userEnteredPadding, defaults) => {
  const paddingRight =
    userEnteredPadding?.right ||
    defaults?.paddingRight ||
    defaults?.paddingX ||
    defaults
  const paddingLeft =
    userEnteredPadding?.left ||
    defaults?.paddingLeft ||
    defaults?.paddingX ||
    defaults
  const paddingTop =
    userEnteredPadding?.top ||
    defaults?.paddingTop ||
    defaults?.paddingY ||
    defaults
  const paddingBottom =
    userEnteredPadding?.bottom ||
    defaults?.paddingBottom ||
    defaults?.paddingY ||
    defaults

  return { paddingRight, paddingLeft, paddingTop, paddingBottom }
}

// TODO: should support numbers and strings in body font size
export const getMobileBodyFontSize = ({ theme }) => {
  return theme?.fontSizes?.mobile?.body || '16px'
}

export const getDesktopBodyFontSize = ({ theme }) => {
  return theme?.fontSizes?.body || '16px'
}

export const getBodyFontSize = ({ theme }) => {
  const mobileFontSize = getMobileBodyFontSize({ theme })
  const desktopFontSize = getDesktopBodyFontSize({ theme })
  return {
    fontSize: [mobileFontSize, mobileFontSize, desktopFontSize],
  }
}

export const isInternalLink = (urlOrPath) =>
  urlOrPath?.startsWith('/') || urlOrPath?.startsWith('#')

export const isMailtoLink = (href) => href?.startsWith('mailto:')

export const isTelephoneLink = (href) => href?.startsWith('tel:')

export const getImageFromData = (image, title) => {
  if (!image) {
    return null
  }
  return image?.gatsbyImageData ? (
    <GatsbyImage image={getImage(image?.gatsbyImageData)} alt={title} />
  ) : (
    <img src={image} alt={title} />
  )
}

// ref: https://stackoverflow.com/a/4373037
export function naturalSort (a, b) {
  let a1
  let b1
  const rx = /(\d+)|(\D+)/g
  const rd = /\d+/
  a = String(a).toLowerCase().match(rx)
  b = String(b).toLowerCase().match(rx)
  while (a.length && b.length) {
    a1 = a.shift()
    b1 = b.shift()
    if (rd.test(a1) || rd.test(b1)) {
      if (!rd.test(a1)) return 1
      if (!rd.test(b1)) return -1
      if (a1 !== b1) return a1 - b1
    } else if (a1 !== b1) return a1 > b1 ? 1 : -1
  }
  return a.length - b.length
}
