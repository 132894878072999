import React from 'react'
import styled from 'styled-components'

import Video from '@anvilco-pkg/ui/components/video/YoutubeVideo'

const StyledVideo = styled(Video)`
  margin-bottom: 1rem;
`

export default function DocsYTVideo (props) {
  return <StyledVideo {...props} autoplay={false} />
}
